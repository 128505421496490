
#icon{
    height:1.8em;
    width:1.8em;
    padding:0.2em;
    background-color:#000;
    border-radius:1em;
    color:#fff;
}

#icon:hover{
    background-color: dodgerblue;
}



/* media queries */

@media only screen and (min-width: 320px) and (max-width: 479px){
    #rootFont{
        font-size: 10px;
    }
  }

@media only screen and (min-width: 480px) and (max-width: 767px){ 
    #rootFont{
        font-size: 12px;
    }
 }

@media only screen and (min-width: 768px) and (max-width: 991px){ 
    #rootFont{
        font-size: 14px;
    }
 }

@media only screen and (min-width: 992px){ 
    #rootFont{
    font-size: 16px;
} }