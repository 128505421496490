// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

// New CSS Written By @Prasad Lokhande
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.font-roboto {
  font-family: "Roboto", sans-serif !important;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.linkFormat {
  color: #135cfd;
  cursor: pointer;

  &:hover {
    color: #187de4;
  }
}

.filter-label {
  display: block;
  margin-top: 5px;
}

.react-bootstrap-table {
  overflow-x: auto;

  th {
    outline: none;

    &.sortable {
      .filter-label {
        display: block;
        margin-top: 5px;
      }
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.react-bootstrap-table-pagination {
  float: left;
}

.imgStyle {
  position: relative;
  width: 110px;
  height: 110px;
  border: 1px solid #292929;
  box-shadow: 0 3px 12px #aaa;
  border-radius: 8px;
  padding: 12px;
  margin: 8px;
}

.form-control {
  box-shadow: 0 0 2px #3699ff;
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

.cal-wrapper {
  width: 100%;
}

.add-attachment {
  margin-top: 5px;
  float: left;
  display: flex;
  text-transform: capitalize;
  gap: 10px !important;
  background-color: #c6e2d9 !important;
  color: #0094ff !important;
}

.btn.btn-light i {
  color: #007cff7a;
  margin: 0 10px;
}

.attachment-wrapper {
  margin-top: 5px;
}

label {
  color: #1e1e2d !important;
}

.form-control {
  height: 42px !important;
  border: 2px solid #d7e6f6 !important;
  box-shadow: 0 3px 6px #aaa;
}

textarea.form-control {
  height: 100px !important;
}

.file {
  position: relative;
  display: none;
}

.file-label {
  cursor: pointer;
  position: absolute;
  color: #fff !important;
  background: #000023;
  width: 110px;
  bottom: 12px;
  left: 20px;
  box-shadow: 0 0 16px #fefefe;
  font-weight: 600;
  padding: 8px 2px;
  border-radius: 13px 13px 0 0;
  text-align: center;
}

.img-wrapper {
  position: relative;
  margin: 0 6px;
}

.img-wrapper .spanx {
  z-index: 10;
  cursor: pointer !important;
  position: absolute;
  right: -5px;
  top: -5rem;
  font-size: 14px;
  font-weight: 600;
  background: #1b1b28;
  color: #fff;
  padding: 3px 10px;
  border-radius: 50%;
  box-shadow: 0px 1px 4px #000;
}

// overriding background color
#kt_content {
  background: #e5e8f1;
  overflow-x: hidden;
}

.hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}

.submit-btn {
  margin-top: 5px;
  padding: 10px 45px !important;
  border-radius: 6px;
  float: right;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.submit-btn-light {
  background: #b6cee7 !important;
  color: #3697fc !important;
  border: none !important;
}

.form-check-input {
  margin-top: 0rem;
  margin-left: -1.5rem;
  height: 16px;
  width: 16px;
}

.rbt-input-multi {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  // overflow:auto;
  // resize: both;
  height: 100px !important;
}

.profile-label {
  margin-top: -58px;
}

.product-row {
  margin-bottom: 20px;

  .file-group {
    position: relative;

    .pro-file {
      display: none;
    }
    .pro-file-label {
      position: relative;
      box-shadow: 0 3px 12px #aaa;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      height: 110px;
      width: 110px;
      margin: 8px;
    }

    .pro-file-label:before {
      content: "";
      position: absolute;
      top: 10%;
      left: 1rem;
      height: 80%;
      width: 78%;
      background-image: url("/media/custom/NoImage.jpg");
      background-position: center center;
      background-size: cover;
      padding: 32px;
    }

    .pro-file-label:after {
      content: "Browse File";
      position: absolute;
      left: 0;
      bottom: 0rem;
      height: 2.7rem;
      width: 100%;
      background: #000023;
      color: #fff;
      border-radius: 12px 12px 0 0;
      box-shadow: 0px 1px 4px #000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// auction complete scss
.RankComp {
  position: relative;
  top: -2%;
  min-height: 80vh;

  background-size: cover;
  padding: 64px;

  .circle {
    margin: 20px 0;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background: #1f1f1f;
    border: 6px solid #0080ff;
    @include flex-center;

    h1 {
      color: #fff;
      font-size: 2.5rem;
    }
  }

  .circleRed {
    border: 6px solid #e83d38;
  }

  .product-count-down {
    bottom: -12%;
    transform: translateX(-50%);

    .product-count-down-box {
      height: 80px !important;
      width: 270px !important;
    }
  }
}

.Onerankcomp {
  background-image: url("/media/bg/bgrankl1.png");
}

.NoRankComp {
  background-image: url("/media/bg/bgnorank.png") !important;
}

.liveAuctionContent {
  // border:2px solid red;
  max-width: 500px;
  text-align: center;
  font-family: "Montserrat", sans-serif;

  h3 {
    font-weight: bold;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
}

.connectionLost {
  height: 400px;
  width: 500px;
  margin-left: 16px;
}

.adminRankRow {
  .adminRankCol1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .productImage {
      position: relative;
      img {
        max-height: 170px;
        max-width: 170px;
        border: 6px solid #abd3d6;
        border-radius: 6px;
        padding: 0.7rem;
      }
      span {
        position: absolute;
        top: 0;
        left: 20%;
        transform: transformX(-25%);
        background: #9bf2b8;
        color: #2c7b15;
        padding: 0.5rem 1rem;
        font-weight: bold;
      }
    }
    .productData {
      max-width: 400px;
      margin: 1rem;
    }
    .hurdleRate {
      display: flex;
      align-items: center;
      p {
        &:nth-child(1) {
          font-weight: 200;
          font-size: 1.5rem;
        }
        &:nth-child(2) {
          margin-left: 1.5rem;
          font-size: 2rem;
          font-weight: 600;
        }
      }
    }
  }
  .adminRankCol2 {
    .rankingOuter {
      max-width: 400px;
      max-height: 300px;
      background: #f1f9ff;
      padding: 1rem;
      h4 {
        margin-left: 2.5rem;
        padding: 1rem 0;
      }
      .ranksData {
        display: flex;
        height: 80%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .rankData {
          display: flex;
          align-items: center;
          margin: 1rem 0;
          .rankerProfile {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            box-shadow: 0 4px 4px #aaa;
          }
          .rankBg {
            position: relative;
            img {
              max-width: 200px;
              height: 26px;
            }
            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: bold;
            }
          }
          .rankRate {
            position: relative;
            left: -8px;
            background: #1f1f1f;
            color: #fff;
            padding: 8px;
            border-radius: 6px;
          }
        }
      }
    }
  }
}

// auction close
.dert {
  display: flex;
  flex-wrap: wrap;
}

// BS table css
.react-bootstrap-table {
  // text-align:center;
  thead {
    margin-bottom: 32px !important;

    th {
      text-align: center;
      background: #1b1b28;
      color: #fff;
      border: none !important;
      vertical-align: middle !important;
      white-space: nowrap;

      &:nth-child(1) {
        border-radius: 8px 0 0 0;
      }

      &:nth-last-child(1) {
        border-radius: 0 8px 0 0;
      }
    }

    tr:nth-child(2) {
      padding-top: 30px;
    }
  }

  td {
    vertical-align: middle !important;
  }
}

#eoiTable {
  thead {
    th {
      cursor: default;
    }
  }

  tbody {
    td {
      cursor: default;
    }
  }
}

.bid-price {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.bid-edit:hover {
  cursor: pointer;
  transform: scale(1.2);
  text-align: right;
}

#showAllRfpTable {
  thead {
    th {
      &:nth-child(3),
      &:nth-child(5) {
        cursor: default !important;
      }
    }
  }

  tbody {
    td {
      cursor: default;

      &:nth-child(1) {
        color: #135cfd;
        transition: 0.2s;
      }

      &:nth-child(1):hover {
        color: #187de4;
        cursor: pointer;
      }
    }
  }
}

#listRfpTable {
  cursor: default;

  th {
    cursor: default;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      cursor: pointer;
    }
  }
}

#listAuctionTable {
  cursor: default;

  thead {
    cursor: default !important;

    tr {
      cursor: default !important;
    }
  }
}

#listOrganizationTable {
  thead {
    cursor: default;
  }
}

// buttons

.add-btn {
  position: relative;
  padding: 8px 24px;
}

.eoi-add-btn {
  margin: 0 12px 12px auto;
}

.align-right {
  text-align: right;
}

.container button:last-child {
  margin-right: 3px;
}

.add-organization-btn {
  text-align: end;
  margin: auto 12px 12px auto;
}

.viewRFP {
  .roww {
    border-left: 2px solid rgba(57, 58, 74, 0.2);
    border-right: 2px solid rgba(57, 58, 74, 0.2);

    &:nth-child(odd) {
      background: #f0f0f1;
    }

    &:nth-child(even) {
      background: #fff;
    }

    .bor {
      border-right: 2px solid rgba(57, 58, 74, 0.26);
    }

    .coll {
      font-size: 14px;
      padding: 16px;

      &:nth-child(1) {
        font-weight: 600;
      }
    }
  }

  .product-row {
    margin-bottom: 0;

    .img-wrapper {
      div {
        display: none;
      }
    }
  }
}

// auction page design
.vu-box {
  // @include flex-center;
  font-size: 10px;
  width: 20em;
  padding: 1.2em;
  background: #c0d7eb;
  box-shadow: 0.1em 0.2em 0.2em #aaa;
  // position: relative;

  // top: -1.2rem !important;
  //   left: -1.2rem;

  .vu-text {
    font-size: 1.6em;
    margin-left: 0.4em;
    font-weight: bold;
  }

  .vu-icon {
    background: #fff;
    color: #5c7180;
    padding: 0.4em !important;
    border-radius: 50%;
  }
}

.product-info {
  @include flex-center;
  font-size: 10px;

  .product-content {
    margin: 1em 1em;

    .liveAuction__orgName {
      text-align: center;
      font-size: 2.2em;
    }
    .liveAuction__productTitle {
      text-align: center;
      font-size: 2em;
    }
    .liveAuction__productTnc {
      text-align: center;
      font-size: 1.8em;
    }
  }

  .product-img-outer {
    border: 4px solid #c0d7eb;
    padding: 0 16px;
    border-radius: 8px;
    box-shadow: 0 0 4px #c0d7eb;
  }
}

.padding-right-auction{
  padding-right:20px;
}

.product-main {
  display: flex;
  align-items: center;
  // justify-content:center;
  // padding:0 32px;
  margin-top: 2.5rem;

  .product-main-col1 {
    @include flex-center;
    // justify-content:flex-start;
  }

  .product-main-col2 {
    position: relative;
    margin-top: 2rem;
    @include flex-center;

    .bid-circle {
      position: relative;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      background: #1f1f1f;
      border: 5px solid #0080ff;

      h1 {
        @include position-center;
        padding: 0;
        margin: 0;
        color: #fff;
      }

      .bid-circle-small {
        position: absolute;
        height: 35px;
        width: 35px;
        background: #ff0aa5;
        border-radius: 50%;
        box-shadow: 0 0 16px #aaa;
      }
    }

    &:before {
      position: absolute;
      content: "Rank";
      font-size: 26px;
      font-weight: 600;
      top: -25%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .product-main-col3 {
    display: flex;
    align-items: center;
    justify-content: center;

    .form-control {
      // display:inline-flex;
      // width:50%;
      // height:45px;
      // border-radius:8px 0 0 8px;
      box-shadow: none;
      border: none;
    }

    button {
      //  display:inline-flex;
      //  align-items:center;
      height: 38px;
      margin-top: 2px;
      //  box-shadow:0 2px 6px #aaa;
      //  text-align:center;
      //  border-radius:0 8px 8px 0;
    }

    small {
      display: none;
    }
  }
}

.product-count-down {
  // position: absolute;
  // @include flex-center;
  // background-color: greenyellow;
  bottom: 0;
  display: flex;

  // left: 50%;

  .product-count-down-inner {
    @include flex-center;
    margin-top: 50px;

    .product-count-down-box {
      position: fixed;
      bottom: 0%;
      @include flex-center;
      height: 64px;
      width: 250px;
      background: #011021;
      border: 5px solid #0080ff;
      border-bottom: none;
      border-radius: 50px 50px 0 0;
      font-size: 26px;
      color: #fff;
      padding-top: 14px;

      i {
        color: #fff;
      }

      span {
        margin-left: 16px;
        font-weight: bolder;
      }
    }
  }
}
.dashboard__slider {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.black-field {
  background: #aaa !important;
  color: #000;
  border: none !important;
  outline: none !important;
}

// user Profile Form
.update-profile-upper {
  position: relative;
  top: -12rem;
}
.dash-card {
  position: relative;
  width: 200px;
  height: 110px;
  // margin: 2rem auto;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 16px;
    h3 {
      font-size: 1em;
      font-weight: 600;
      color: #000;
      z-index: 1;
    }
    span {
      background: #212121;
      color: #fff;
      border-radius: 16px;
      padding: 6px 16px;
    }
    .icon {
      max-height: 4.5rem;
      max-width: 4.5rem;
      margin-top: 4px;
    }
  }
}
// dashboard design code
.bd-cards {
  background: url("/media/bg/dashboard.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 2rem auto;
  position: relative;
  top: -15px;
  margin: 0px auto;
  display: flex;
  justify-content: space-around;
  flex-basis: 0;
  flex-grow: 1;

  .bd-cardsOuter {
    display: flex;
    justify-content: space-around;

    .bd-card {
      position: relative;
      padding: 15px;
      height: 100px;
      width: 200px;
      justify-content: space-around;
      align-items: center;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0 1px 4px #fff;

      svg {
        vertical-align: baseline !important;
      }

      .bd-icon {
        max-height: 40px;
        max-width: 40px;
        position: relative;
        top: 8px;
        // &:nth-child(1){
        //   height:50px !important;
        // }
      }

      h2 {
        position: relative;
      }
    }
  }
}

#owl-dashboard {
  .owl-dots {
    display: none;
  }

  .owl-nav {
    overflow: hidden;
    height: 0px;

    .owl-prev,
    .owl-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: #000023;
      color: #fff;
      border-radius: 50%;
      height: 3rem !important;
      width: 3rem !important;
      z-index: 20 !important;
      line-height: 0;
      box-shadow: 0 2px 6px #000;

      img {
        height: auto;
        width: 20px;
      }
    }

    .owl-prev {
      left: -2rem;
    }

    .owl-next {
      right: -1rem;
    }
  }
  .dashboardSlider {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
  }
}

.sliderImg {
  background: "#000";
  width: 200px;
  height: 200px;

  box-shadow: 0 1px 2px #000;
  border-radius: 6px;
  object-fit: contain;
}

// All RFP Page
.allRfpImg {
  height: 100px;
  width: 100px;
  border: 1px solid #aaa;
  box-shadow: 0 0 2px #000;
  margin: 4px;
}

.copyright {
  margin-left: 50px;
}

// sidebar
#kt_aside_menu,
.brand-dark .brand,
#kt_aside_menu_wrapper {
  background: #000023 !important;
}

// #kt_aside {
// transition:0.35s ease all !important;
// }

.menu-item {
  position: relative;
  // transition:5s ease all;
  // transform-origin:left;
}

.menu-text-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(-50%, -50%);
}

// scroll to top
#kt_scrolltop {
  border-radius: 50% !important;
  height: 32px !important;
  width: 32px !important;
  color: #fff !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  right: 4px !important;
  bottom: 45px;
}

// userlist css
#userlist {
  cursor: default;

  th {
    cursor: default;
  }

  tbody {
    tr {
      td:nth-child(1),
      td:nth-child(2),
      td:nth-child(3),
      td:nth-child(5) {
        cursor: pointer;
        color: #135cfd;
      }
    }
  }
}

#userTable {
  cursor: pointer;
  cursor: default;

  th {
    cursor: default;
  }
}

.checkbox {
  padding-left: 8px !important;
}

.input-checkbox {
  position: relative;
  top: 4px;
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  outline: none;
  transition: 0.5s;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border: 2px solid #aaa;
    box-sizing: border-box;
    transition: 0.5s;
  }

  &:checked {
    &:before {
      width: 8px;
      border-left: #398cee !important;
      border-color: #398cee !important;
      border-top: #398cee !important;
      transform: rotate(45deg);
    }
  }
}

.input-checkbox-outer {
  max-width: 380px;
  width: 100%;
  position: relative;
  padding: 4px;
}

.bRadius6 {
  border-radius: 6px !important;
}

.organizationImage {
  // position: relative;
  width: 120px;
  height: 120px;
  border: 1px solid #292929;
  box-shadow: 0 3px 12px #aaa;
  border-radius: 8px;
  padding: 12px;
  // margin:8px;
}
.organizationImageLabel {
  cursor: pointer;
  position: absolute;
  color: #fff !important;
  background: #000023;
  width: 120px;
  // bottom: 12px;
  // left: 20px;
  box-shadow: 0 0 16px #fefefe;
  font-weight: 600;
  padding: 8px 2px;
  border-radius: 13px 13px 0 0;
  text-align: center;
  margin-top: -18px;
}
.organizationImageContainer {
  display: flex;
  align-items: center;
}
.adminDashBoard {
  .liveAlert {
    display: flex;
    background-color: #fff;
    border-radius: 15px;
    // border-radius: 2em;
    justify-content: space-around;
    align-items: center;
    justify-items: center;
    .bellCard {
      display: flex;
      align-items: center;
      margin-top: 10px;
      // justify-content: space-between;
      p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        // font-size: 2em;
        // margin-left: .5em;
        margin-left: 5px;
        // margin-top: .5em;
        margin-top: 5px;
        color: #000000;
      }
    }
    .liveAlertCard {
      background: #f2f3f7;
      color: #201e1e;
      border-radius: 10px;
      padding: 2px 10px;
      width: 100%;
      font-size: 10px;
      font-family: Poppins;
    }
  }
  .liveStastics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .liveStastics__cardContainer {
      display: flex;
      justify-content: space-around;
      flex-grow: 0.9;
      .stasticsCard {
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
        padding: 0px 20px;
        .heading {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          margin-top: 10px;
          margin-left: -5px;
        }
        .row {
          display: flex;
          justify-content: space-between;
          // align-items: center;
          padding-left: 10px;
          padding-right: 10px;
          .datalabel1 {
            font-family: Barlow;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            text-align: center;
            color: #696694;
          }
          .datalabel2 {
            font-family: Barlow;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            text-align: center;
            color: #ff6269;
          }
          .data {
            font-family: Barlow;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            color: #0e0e99;
          }
        }
      }
    }

    .liveStastics__iconContainer {
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      border-radius: 10px;
      p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000023;
        margin-top: 10px;
        margin-left: 5px;
      }
      .icon {
        width: 25px;
        height: 25px;
      }
    }
  }
  .chartRow {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .chartContainer {
      background-color: #fff;
      border-radius: 15px;
      padding-bottom: 20px;
      padding-right: 15px;
      padding-left: 15px;
      margin-bottom: 10px;
      .chartHeading {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #000000;
        margin-left: 15px;
        margin-top: 10px;
      }
    }
  }
}

/*===================================responsive start here================================================*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .RankComp {
    padding: 0px !important;
    min-height: 85vh !important;

    .content {
      width: 250px !important;
    }

    .product-count-down {
      padding-top: 100px !important;
      margin-top: 100px !important;
      bottom: 0% !important;
      transform: translateX(-50%);

      .product-count-down-box {
        height: 80px !important;
        width: 270px !important;
      }
    }
  }

  .bid-edit {
    margin: 2px 5px 0 5px;
  }

  //  .rankingOuter{
  //       width:280px !important;
  //       height:300px !important;
  //       .rankBg{
  //         img{
  //           max-width:150px !important;
  //           height:16px;
  //         }
  //       }
  //  }
  .connectionLost {
    max-height: 200px !important;
    max-width: 250px !important;
  }
  .sliderImg {
    width: 160px;
    height: 160px;
  }
  .bd-card {
    width: 120px !important;
  }
  .connectionLost {
    max-height: 200px !important;
    max-width: 250px !important;

    .file-label {
      width: 100px;
    }

    .coll {
      font-size: 12px;
    }

    .product-main {
      margin-top: 0 !important;
    }

    .product-main-col1 {
      justify-content: center !important;
    }

    .product-main-col2 {
      margin: 16px 0;
    }

    .pro-file-label {
      height: 100px;
      width: 100px;
    }

    .pro-file-label:after {
      bottom: 10px;
    }

    .spanx {
      top: -4rem !important;
    }

    .profile-label {
      margin-top: -55px;
    }

    .update-profile-upper {
      position: relative;
      top: 0;
    }

    .auction-card {
      padding-bottom: 16px !important;

      h2 {
        font-size: 12px !important;
      }
    }

    .vu-box {
      top: -1.2rem !important;
      left: -1.2rem;
    }

    .product-count-down {
      transform: translateX(-45%);
    }

    .bid-circle {
      height: 120px !important;
      width: 120px !important;
    }

    #owl-dashboard .owl-nav .owl-next {
      right: -2rem !important;
    }

    #owl-dashboard .item {
      height: 500px;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 768px) {
    .row-upper,
    .col-upper {
      position: relative;
      top: -40px;
    }

    .imgStyle,
    .pro-file-label {
      width: 110px;
      height: 110px;
      border: 1px solid #aaa;
    }

    .sliderImg {
      width: 160px;
      height: 160px;
    }
    .bd-card {
      h2 {
        font-size: 16px !important;
      }

      .file-label {
        width: 100px;
      }

      .auction-card {
        h2 {
          font-size: 15px !important;
        }
      }

      .bd-icon {
        top: 0 !important;
      }

      #owl-dashboard .item {
        height: 200px;
      }
      .adminRankRow {
        padding: 40px;
      }
      .bid-edit {
        margin: 2px 5px 0 5px;
      }
    }
    @media only screen and (min-width: 786px) {
      .bd-card {
        width: 160px !important;
      }
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
      .row-upper {
        position: relative;
        top: -40px;
      }

      .col-upper {
        position: relative;
        top: -40px;
      }

      .imgStyle,
      .pro-file-label {
        width: 100px !important;
        height: 100px !important;
        border: 1px solid #aaa;
      }

      .product-content {
        padding: 0 16px;
      }

      .product-info {
        text-align: center;
      }

      .auction-card {
        padding: 16px 14px !important;
      }

      .file-label {
        width: 100px;
      }
      #owl-dashboard .item {
        height: 300px;
      }
      .sliderImg {
        width: 150px;
        height: 150px;
      }
    }
    #owl-dashboard .item {
      height: 300px;
    }
  }

  /* Large devices (laptops/desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .row-upper {
      position: relative;
      top: -60px;
    }

    .col-upper {
      position: relative;
      top: -60px;
    }

    .imgStyle,
    .pro-file-label {
      width: 130px !important;
      height: 130px !important;
      border: 1px solid #aaa;
    }

    .file-label {
      width: 130px;
    }

    .product-content {
      padding: 1em 16px;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Playfair Display", serif;
    }

    .pro-file-label:before {
      max-width: 120px !important;
    }
  }

  // end of media query
  .google-logout {
    visibility: hidden !important;
  }

  .safrp {
    background: #1b1b28;
    color: #fff !important;
    text-align: center;
    padding: 20px 0;
    border-radius: 8px 8px 0 0;
  }

  // extras media queries breakpoints for dashboard cards
  @media only screen and (min-width: 806px) {
    .myeoi-card {
      padding: 13px 32px !important;

      h2 {
        font-size: 18px !important;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    .myeoi-card {
      padding: 15px 32px !important;
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .vu-box {
    font-size: 7px;
  }
  .product-info {
    @include flex-center;
    font-size: 10px;
  }
  .chartRow {
    flex-direction: column;
  }
}

// small divices
@media only screen and (max-width: 400) {
  .vu-box {
    font-size: 8px;
  }
  .product-info {
    @include flex-center;
    font-size: 2px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .vu-box {
    font-size: 8px;
  }
  .product-info {
    @include flex-center;
    font-size: 7px;
  }
  .chartRow {
    flex-direction: column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .vu-box {
    font-size: 9px;
  }
  .product-info {
    @include flex-center;
    font-size: 8px;
  }
  .chartRow {
    flex-direction: column;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .vu-box {
    font-size: 10px;
  }
  .product-info {
    @include flex-center;
    font-size: 9px;
  }
  .chartRow {
    flex-direction: column;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .vu-box {
    font-size: 11px;
  }
  .product-info {
    @include flex-center;
    font-size: 10px;
  }
  .chartRow {
    flex-direction: row;
  }
}
